<template>
  <div class="page_wrap">
    <div class="banner_wrap">
      <img class="banner" src="https://cdn.qiumo.net.cn/allsmarketmaterial/LeaseTransMaster-mini/blue/blue_top01.png" />
    </div>
    <div class="border_wrap form_wrap">
      <div class="form-item">
        <div class="label">出行时间</div>
        <van-field class="form-item-content" v-model="form.date" readonly right-icon="arrow" placeholder="请选择出行时间"
          @click="showCalendar = true"></van-field>
      </div>
      <div class="form-item">
        <div class="label">手机号码</div>
        <van-field class="form-item-content" v-model="form.phoneNumber" type="tel" maxlength="11"
          placeholder="请输入手机号码"></van-field>
      </div>
      <div class="form-item">
        <div class="label">验证码</div>
        <van-field class="form-item-content" v-model="form.smsCode" type="digit" placeholder="请输入验证码">
          <template #button>
            <div v-if="!showCountDown" class="sms-buttomn" @click="onSendCode">
              获取验证码
            </div>
            <div v-else class="sms-buttomn conutdown">
              {{ countDown }}秒后重新获取
            </div>
          </template>
        </van-field>
      </div>
      <div class="form-button">
        <img @click="onSubmit" src="https://cdn.qiumo.net.cn/allsmarketmaterial/LeaseTransMaster-mini/blue/blue_btn.png"
          alt="">
      </div>
    </div>
    <div class="border_wrap rule_wrap">
      <img class="rule-icon"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LeaseTransMaster-mini/blue/blue_rule.png" />
      <div class="rule-text">
        {{ ruleText }}
      </div>
    </div>
    <van-calendar v-model="showCalendar" color="#0B5FBF" @confirm="onConfirmDate" />
  </div>
</template>

<script>
import { ruleText } from "./data.js";
import {
  getReserveCodeAPI,
  checkReserveCodeAPI,
} from "@/api/leaseTransMaster/index.js";
import dayjs from "dayjs";
let timer = null;
export default {
  data() {
    return {
      ruleText,
      form: {
        date: "",
        phoneNumber: "",
        smsCode: "",
      },
      showCalendar: false,
      countDown: 0,
      showCountDown: false,
      source: "",
    };
  },
  created() {
    const { source = "" } = this.$route.query;
    this.source = source;//来源  去哪儿/凯撒出行
    console.log('来源---source',this.source ,location.origin)
  },
  methods: {
    // 确认选择日期
    onConfirmDate(val) {
      this.showCalendar = false;
      this.form.date = dayjs(val).format("YYYY-MM-DD");
    },
    // 点击发送验证码
    onSendCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phoneNumber) {
        return this.$toast("请输入手机号码");
      } else if (!filterPhone.test(this.form.phoneNumber)) {
        return this.$toast("请输入正确的手机号码");
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "发送中...",
      });
      getReserveCodeAPI({
        phone_number: this.form.phoneNumber,
      })
        .then((res) => {
          if (res.code != 0) return this.$toast(res.message || "发送失败");
          this.$toast("发送成功");
          this.showCountDown = true;
          this.countDown = 60;
          timer = setInterval(() => {
            if (this.countDown > 0) {
              this.countDown -= 1;
            } else {
              this.showCountDown = false;
              clearInterval(timer);
            }
          }, 1000);
        })
        .catch((err) => {
          this.$toast(err.message || "发送失败");
        });
    },
    // 提交预定
    onSubmit() {
      let filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.date) {
        return this.$toast("请选择出行时间");
      } else if (!this.form.phoneNumber) {
        return this.$toast("请输入手机号码");
      } else if (!filterPhone.test(this.form.phoneNumber)) {
        return this.$toast("请输入正确的手机号码");
      } else if (!this.form.smsCode) {
        return this.$toast("请输入验证码");
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "提交中...",
      });
      checkReserveCodeAPI({
        travel_time: this.form.date,
        phone_number: this.form.phoneNumber,
        phone_code: this.form.smsCode,
        source: this.source
      })
        .then((res) => {
          if (res.code != 0) return this.$toast(res.message || "预定失败");
          this.$toast.clear();
          this.$router.push("/leaseTransMaster/blue_successful");
        })
        .catch((err) => {
          this.$toast(err.message || "预定失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.page_wrap {
  background-color: #0B59B3;
  min-height: 100vh;
  padding-bottom: 32px;

  .banner_wrap {
    height: 820px;
    overflow: hidden;
    // margin-bottom: 60px;

    .banner {
      width: 100%;
    }
  }

  .border_wrap {
    margin: auto;
    margin-top: 60px;
    padding: 30px;
    width: 690px;
    background: linear-gradient(180deg, #0B5FBF 0%, #0B5AB5 100%);
    border-radius: 16px;
    border: 1px solid #81B8F7;
  }

  ::v-deep .form_wrap {
    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .label {
        width: 130px;
        font-size: 28px;
        color: #BADAFF;
      }

      .form-item-content {
        flex: 1;
        background: #E3F0FF;
        border-radius: 12px;
        padding: 20px 32px;

        input::placeholder {
          color: #9C9FA7;
        }

        .sms-buttomn {
          width: 180px;
          margin: 0;
          color: #803C00;
          border-left: 2px solid #C5D2E0;
          text-align: right;
          font-size: 28px;
        }

        .conutdown {
          width: 200px !important;
          color: #666 !important;
          font-size: 24px !important;
        }
      }
    }

    .form-button {
      width: 634px;
      height: 110px;
      margin: auto;
      margin-top: 40px;
      background-size: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 38rpx;
      color: #fff8dd;

      img {
        width: 100%;
      }
    }
  }

  .rule_wrap {
    .rule-icon {
      width: 380px;
      margin-left: 125px;
      margin-bottom: 30px;
    }

    .rule-text {
      font-size: 24px;
      color: #AAC8F0;
      line-height: 33px;
      white-space: pre-wrap;
    }
  }
}
</style>
