export const ruleText = `1、产品介绍：
（1）本品为北京分音塔科技有限公司出品的准儿翻译机Pro（JoneR Translator Pro）
（2）主要功能：拍照翻译、离线翻译、景区讲解、全球上网、SIM流量卡、WIFI共享、热点/WIFI链接、谷歌搜索、AI实物识别、AI语音助手、目的地攻略、闪光灯、GPS与地图、SOS、世界时钟、天气查询、汇率换算
（3）SIM流量卡使用范围：
①亚洲：日本、韩国、新加坡、马来西亚、泰国、越南、印度尼西亚、菲律宾、柬埔寨、老挝、蒙古、斯里兰卡、阿联酋
②大洋洲：澳大利亚、新西兰
③欧洲：俄罗斯、奥地利、比利时、保加利亚、克罗地亚、塞浦路斯、捷克共和国、丹麦、爱沙尼亚、芬兰、法国、德国、直布罗陀、希腊、匈牙利、冰岛、爱尔兰、意大利、拉脱维亚、列支敦士登、立陶宛、卢森堡、马耳他、荷兰、挪威、波兰、葡萄牙、罗马尼亚、斯洛伐克、斯洛文尼亚、西班牙、瑞典、英国
④美洲：美国、法属圭亚那、瓜德罗普、马提尼克、留尼汪、马约特
2、产品预订：用户预订后，工作人员将与用户联系并引导用户下单，用户下单成功后，翻译机将会以邮寄形式送达，如有疑问，请咨询4001189599
3、押金与资费：本品押金为1430元，租金为30元/日，下单时需一次性缴纳押金与租金。支付押金可选择芝麻免押/直接支付押金
4、设备维护：在租用设备过程中，需确保设备完好，未经商家同意，不得私自拆卸、私自升级系统或软件服务
5、产品归还：用户确认租期结束，需根据指引在【租译通】支付宝小程序上进行归还申请，并将翻译机邮寄寄回，如有疑问，请咨询4001189599
（1）提前归还：不允许提前归还，早于约定归还日归还产品，将按原租期进行计费
（2）逾期归还：若超过约定归还日归还，则将在押金中扣除逾期租金，逾期租金为30元/日。若逾期15天并确认不归还，则不再归还押金
（3）收货地址：中国 (浙江)自由贸易试验区杭州市滨江区西兴街道协同路190号B座翻译机收发点
（4）收件人：帖芯科技
（5）收件人联系方式：19517986903
`;
